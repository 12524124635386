import React from "react";
import { Link } from "react-router-dom";

const News_one = () => {
  return(
    <>
    <br />
    <br />
      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container-lg mx-5">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/aktualnosci">Aktualności</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Ten artykuł</li>
          </ol>
        </div>
      </nav>
      <div className="container-lg mb-5 p-5  ">
        <p class="text-muted">Opublikowano dnia: <b>7 sierpnia 2021</b></p>
        <h1 class="fw-bolder mt-4 mb-5">
          Sztuczna inteligencja uchroni uprawy przed szkodnikami. Opryski będą stosowane tylko tam, gdzie występują
        </h1>
        <p className="lead fw-normal">
          Nowe technologie coraz skuteczniej wspierają pracę rolników. Dzięki inteligentnej platformie tworzonej przez polski start-up uda się uchronić uprawy przed działaniem szkodników poprzez stosowanie chemicznych oprysków wyłącznie w miejscach, w których jest to konieczne. Wykonywane przez autonomiczne drony fotografie zastąpią zdjęcia satelitarne w procesie planowania obfitości wysiewów nasion, a oparty na działaniu robotów system wykona za rolnika szereg prac, począwszy od przygotowania gleby, aż po umieszczenie sadzonek na grządkach.
        </p>
        <p className="lead fw-light mt-4"><i>
        – System SmartGrow będzie się składał z platformy obliczeniowej ze specjalistycznym algorytmem oceny i predykcji danych, systemu czujników mierzących parametry środowiska wzrostu roślin oraz w pełni zautomatyzowanej cyfrowej pułapki feromonowej – zapowiada w rozmowie z agencją informacyjną Newseria Innowacje Grzegorz Piech, członek zarządu SmartGrow.
        </i></p>
        <p className="lead fw-normal mt-4">
        Projekt realizowany przez polski start-up ma na celu optymalizację procesów ochrony roślin. Dzięki powstającemu systemowi SmartGrow plantatorzy będą mogli w optymalny sposób stosować preparaty chemiczne. Dzięki temu ich skuteczność będzie dużo większa, a rośliny będą mniej obciążone pozostałościami chemicznymi.  
        </p>

        <p className="lead fw-light mt-4"><i>
        – Informacje z czujników będą spływały do naszej platformy, dzięki czemu będziemy mogli uzyskać informacje o momencie pojawienia się szkodnika, jego ilości oraz tendencji rozwojowej. Plantator mając takie informacje, będzie mógł dynamicznie i racjonalnie podejmować działania odnośnie do ochrony roślin, przez co zwiększy się jego ekonomia w gospodarstwie, zaoszczędzi czas, a co najważniejsze, rośliny będą mniej obciążone chemią – wyjaśnia Grzegorz Piech.
        </i></p>

      </div>

    </>
  )
}

export default News_one;