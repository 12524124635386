import React from "react";

const Contact = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Kontakt</h1>


        <section class="py-5">
          <div class="container px-0">
          <div className="card card-body mb-5 bg-light pt-5">
            <h2 className="text-center h2 fw-normal mb-4">Dane kontaktowe</h2>

            <div class="row gx-5 align-items-center mb-3">
              <div class="col-lg-4 text-center mb-5 mb-lg-0">
                <div class="fw-bold mb-2 lead">Adres</div>
                <div class="">24-140 Nałęczów</div>
                <div class="">ul. Bochotnica 62</div>
              </div>
              <div class="col-lg-4 text-center mb-5 mb-lg-0">
                <div class="fw-bold mb-2 lead">Telefon</div>
                <p>(+48) 000-000-000</p>
              </div>
              <div class="col-lg-4 text-center">
                <div class="fw-bold mb-2 lead">Email</div>
                <a href="mailto:smartgrow.pl@gmail.com">smartgrow.pl@gmail.com</a>
              </div>
            </div>
          </div>

            <div className="card pt-5 my-5 bg-light">
              <h2 className="text-center h2 fw-normal my-4">Siedziba spółki</h2>
              <p className="lead fw-light text-center mb-1">20-816 Lublin</p>
              <p className="lead fw-light text-center mb-1">ul. Śliwińskiego</p>
              <p className="lead fw-light text-center mb-4">Polska</p>
              <div class="card card-body shadow border-0 p-0 rounded-3 overflow-hidden">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2496.0669426649933!2d22.55132111581008!3d51.273086835628966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472259ff65355545%3A0xf5db157c774c95f1!2zSsOzemVmYSDFmmxpd2nFhHNraWVnbywgMjAtODE2IEx1Ymxpbg!5e0!3m2!1spl!2spl!4v1632826533671!5m2!1spl!2spl" width="100%" height="300" Style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

            <div className="card pt-5 my-5 bg-light">
              <h2 className="text-center h2 fw-normal my-4">Biuro projektów</h2>
              <p className="lead fw-light text-center mb-1">ul. Bochotnica 62</p>
              <p className="lead fw-light text-center mb-1">24-140 Nałęczów</p>
              <p className="lead fw-light text-center mb-4">Polska</p>
              <div class="card card-body shadow border-0 p-0 rounded-3 overflow-hidden">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2495.463364646091!2d22.228828717387856!3d51.28419643752958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47226051f3de13c1%3A0x43e38a5879e4ee42!2zQm9jaG90bmljYSA2MiwgMjQtMTQwIE5hxYLEmWN6w7N3!5e0!3m2!1spl!2spl!4v1630520247790!5m2!1spl!2spl" width="100%" height="300" Style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>

          </div>
        </section>

    </>
  )
}

export default Contact;