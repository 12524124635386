import React from "react";
import { Route, Link } from "react-router-dom";

import News from '../News'

const News_two = () => {
  return(
    <>
      <br />
      <br />

      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container mx-5 px-4">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/">Strona główna</Link></li>
            <li class="breadcrumb-item"><Link to="/aktualnosci">Aktualności</Link></li>
            <li class="breadcrumb-item active" aria-current="page">
                Sztuczna inteligencja uchroni uprawy przed szkodnikami.
            </li>
          </ol>
        </div>
      </nav>

      <div class="container my-5">
            <div class="row">
                <div class="col-lg-9">
                    {/* <!-- Post content--> */}
                    <article>
                        {/* <!-- Post header--> */}
                        <header class="mb-4">
                            {/* <!-- Post title--> */}
                            <h1 class="fw-bolder mb-1">
                            Sztuczna inteligencja uchroni uprawy przed szkodnikami. Opryski będą stosowane tylko tam, gdzie występują
                            </h1>
                            {/* <!-- Post meta content--> */}
                            <div class="text-muted fst-italic mt-4 mb-1">Opublikowano dnia: 6 sierpnia 2021</div>
                            {/* <!-- Post categories--> */}
                        </header>
                        {/* <!-- Preview image figure--> */}
                        <figure class="mb-4">
                            {/* <img class="img-fluid rounded" src="https://dummyimage.com/900x400/ced4da/6c757d.jpg" alt="..." />   */}
                            <img class="img-fluid rounded" src="/img/sztuczna_inteligencja.png" alt="..." />  
                        </figure>
                        {/* <!-- Post content--> */}
                        <section class="mb-5">
                            <p class="fs-5 mb-4">
                            Nowe technologie coraz skuteczniej wspierają pracę rolników. Dzięki inteligentnej platformie tworzonej przez polski start-up uda się uchronić uprawy przed działaniem szkodników poprzez stosowanie chemicznych oprysków wyłącznie w miejscach, w których jest to konieczne. Wykonywane przez autonomiczne drony fotografie zastąpią zdjęcia satelitarne w procesie planowania obfitości wysiewów nasion, a oparty na działaniu robotów system wykona za rolnika szereg prac, począwszy od przygotowania gleby, aż po umieszczenie sadzonek na grządkach.
                            </p>
                            <p class="fs-5 mb-4">
                                The universe is large and old, and the ingredients for life as we know it are everywhere, so there's no reason to think that Earth would be unique in that regard. Whether of not the life became intelligent is a different question, and we'll see if we find that.
                                </p>
                            <p class="fs-5 mb-4">
                                If you get asteroids about a kilometer in size, those are large enough and carry enough energy into our system to disrupt transportation, communication, the food chains, and that can be a really bad day on Earth.
                                </p>
                            <h2 class="fw-bolder mb-4 mt-5">I have odd cosmic thoughts every day</h2>
                            <p class="fs-5 mb-4">
                                For me, the most fascinating interface is Twitter. I have odd cosmic thoughts every day and I realized I could hold them to myself or share them with people who might be interested.
                                </p>
                            <p class="fs-5 mb-5">
                                Venus has a runaway greenhouse effect. I kind of want to know what happened there because we're twirling knobs here on Earth without knowing the consequences of it. Mars once had running water. It's bone dry today. Something bad happened there as well.
                                </p>
                        </section>
                        <Link to="/aktualnosci"><button type="button" class="btn btn-smartgrow btn-sm px-3">Powrót do listy aktualności</button></Link>
                    </article>

                </div>
                {/* <!-- Side widgets--> */}
                <div class="col-lg-3 ps-4">

                    <p className="lead fw-normal mb-2 ps-3 divider-left">Ostatnie wpisy:</p>

                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <a href="#!" className="text-muted">
                                <small>
                                    Sztuczna inteligencja uchroni uprawy przed szkodnikami.
                                </small>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="#!" className="text-muted">
                                <small>
                                    Ostatnie tygodnie kwitnienia konopi
                                </small>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="#!" className="text-muted">
                                <small>
                                    Konopie włókniste w uprawie wertykalnej
                                </small>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="#!" className="text-muted">
                                <small>
                                    Webinar: Rynek rolno-spożywczy Kazachstanu: szanse dla polskich producentów
                                </small>
                            </a>
                        </li>

                    </ul>

                </div>
            </div>
        </div>  

        <Route exact path="/aktualnosci" component={News} />

    </>
  )
}

export default News_two;