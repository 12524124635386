import React from "react";

const About = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">O nas</h1>

      <section class="py-5 bg-light border-top border-bottom">
          <div class="container px-5 my-5">
            <p className="lead">Prezentacja nr 1</p>
            <div className="d-flex align-items-center justify-content-center">
              <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTvKO-7ww7gZKViUrONralFMOogu6T1WQsHL9Gf_UcbWfxU84xpdNZUGDMMJR7IulOfmSgSTmjGgJ__/embed?start=false&loop=false&delayms=3000" frameborder="0" width="100%" height="500" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
          </div>
      </section>

      <section class="py-5 bg-white">
                <div class="container px-5 my-5">
                    <div class="text-center">
                        <h2 class="fw-bolder mb-5">Zarząd</h2>
                    </div>
                    <div class="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <img class="img-fluid rounded-circle mb-4 px-4" src="/img/jerzy-rosiak-150x150.png" alt="..." />
                                <h5 class="fw-bolder">Jerzy Rosiak</h5>
                                <div class="fst-italic text-muted">Prezes</div>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <img class="img-fluid rounded-circle mb-4 px-4" src="/img/grzgeorz-piech-150x150.png" alt="..." />
                                <h5 class="fw-bolder">Grzegorz Piech</h5>
                                <div class="fst-italic text-muted">Wiceprezes <br /> Główny technolog</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-5 bg-light border-top border-bottom">
                <div class="container px-5 my-5">
                <p className="lead">Prezentacja nr 2</p>
                  <div className="d-flex align-items-center justify-content-center">
                    <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRijoARdfqTMO_w5TeZkdYX4ZAMLhbGscm5Wslm433x0qD3LSpEtYIpKzBTSv_GTg/embed?start=false&loop=false&delayms=3000" frameborder="0" width="100%" height="500" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" />
                  </div>
                </div>
            </section>
      
    </>
  )
}

export default About;
