import React from "react";
import { Route, Link } from "react-router-dom";

const Home = () => {
  return(
    <>
    <Route>
      <div class="position-relative overflow-hidden py-3 py-md-5 text-center  border-bottom main-hero">
        <div class="col-md-5 mx-auto py-5">
          <h1 class="display-4 fw-bold  pt-5 text-white">Smart Grow</h1>
          {/* <img src="logo.png" height="200px" className="" /> */}
          <p class="lead fw-normal text-white">DBAMY O TWOJE PLONY</p>
          <a href="#custom-cards" width="50" height="50">
            <svg xmlns="http://www.w3.org/2000/svg" id="arrow-down" width="50" height="50" fill="#fff" class="bi bi-chevron-down" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
          </a>
        </div>
      </div>

      <div class="container px-4 py-5" id="custom-cards">
    
        <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">

          <div class="col ">
            <Link to="/system" class="w-100 h-100 card-wrapper d-flex rounded">
              <div id="card-one" class="card-img card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">System SmartGrow</h3>
                </div>
              </div>
            </Link>
          </div>
          <div class="col ">
            <Link to="system-nawadniania" class="w-100 h-100 card-wrapper d-flex rounded">
              <div id="card-two" class="card-img card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">System nawadniania</h3>
                </div>
              </div>
            </Link>
          </div>
          <div class="col ">
            <Link to="konopie-siewne" class="w-100 h-100 card-wrapper d-flex rounded">
              <div id="card-three" class="card-img card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Konopie siewne</h3>
                </div>
              </div>
            </Link>
          </div>
          <div class="col ">
            <Link to="#" class="w-100 h-100 card-wrapper d-flex rounded">
              <div id="card-four" class="card-img card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Sklep</h3>
                </div>
              </div>
            </Link>
          </div>
          <div class="col ">
            <Link to="nawozenie" class="w-100 h-100 card-wrapper d-flex rounded">
              <div id="card-five" class="card-img card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Nawożenie</h3>
                </div>
              </div>
            </Link>
          </div>
          <div class="col ">
            <Link to="projekty" class="w-100 h-100 card-wrapper d-flex rounded">
              <div id="card-six" class="card-img card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg">
                <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Projekty</h3>
                </div>
              </div>
            </Link>
          </div>          

        </div>
      </div>

      <div class="px-4 py-5 mt-5 mb-5 text-center border-top border-bottom" Style="background-color: #F5F4F0;">
        <h1 class="fw-bold mt-4 text-smartgrow">Nasza misja</h1>
        <div class="col-lg-8 mx-auto">
          <p class="h3 fw-normal text-muted mb-4">
            Dostarczyć plantatorowi precyzyjną informację wspierającą podejmowanie optymalnej decyzji w uprawie roślin.
          </p>
        </div>
      </div>

      <div class="px-4 py-5 mb-5 text-center">
        <h1 class="fw-bold mt-4 mb-5">O SmartGrow</h1>
        <div class="col-lg-8 mx-auto">
          {/* <div class="ratio ratio-16x9 ">
            <iframe src="animacja.mp4" title="YouTube video" autoplay="false" allowfullscreen></iframe>
          </div> */}
          <div class="ratio ratio-16x9">
            <video controls preload poster="animacja.jpg">
              <source src="animacja.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video> 
          </div>
          <p class="lead mt-5 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>
      
      {/* <div class="container px-4 py-5" id="gallery">
        <h2 class="pb-2 border-bottom">Galeria</h2>
        <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
          
          <div id="carousel" class="carousel slide d-block mx-auto w-100" data-bs-ride="carousel" Style="width: 800px;  max-width: 800px;">
            <div class="carousel-indicators" >
              <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner w-100">
              <div class="carousel-item active">
                <img src="/assets/800x540.png" class="d-block w-100" />
              </div>
              <div class="carousel-item">
                <img src="/assets/800x540a.png" class="d-block w-100" />
              </div>
              <div class="carousel-item">
                <img src="/assets/800x540b.png" class="d-block w-100" />
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          
          </div>
        </div>
      </div> */}
      

    </Route>
    </>
  )
}

export default Home;