import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import './App.css';

import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';
import ScrollToTop from './components/ScrollToTop.js';

import Home from './pages/Home.js'
import System from './pages/System.js'
import About from './pages/About'
import BerryPlants from './pages/BerryPlants'
import MedicalCannabis from './pages/MedicalCannabis'
import Consulting from './pages/Consulting'
import Partnership from './pages/Partnership'
import FarmManagement from './pages/FarmManagement'
import DownloadableMaterials from './pages/DownloadableMaterials'

import MediaAboutUs from './pages/MediaAboutUs'
import Contact from './pages/Contact.js'
import News from './pages/News.js'
import Reference from './pages/Reference.js'
import Gallery from './pages/Gallery.js'
import Projects from './pages/Projects.js'
import Fertilization from './pages/Fertilization.js'
import IrrigationSystem from './pages/IrrigationSystem.js'

import Projects_PARP from './pages/Projects/PARP.js'
import RaspberryFactory from './pages/Projects/RaspberryFactory.js'
import BlueberryFactory from './pages/Projects/BlueberryFactory.js'
import IrrigationSystemProject from './pages/Projects/IrrigationSystemProject.js'

import News_one from './pages/newsArticles/News_one.js'
import News_two from './pages/newsArticles/News_two.js'

function App() {
  return (
    <>
    <BrowserRouter>
      <Navbar />
      <main className="" Style="margin-top: 100px">
        <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/system" component={System} />
            <Route exact path="/o-nas" component={About} />
            <Route exact path="/media-o-nas" component={MediaAboutUs} />
            <Route exact path="/rosliny-jagodowe" component={BerryPlants} />
            <Route exact path="/konopie-siewne" component={MedicalCannabis} />
            <Route exact path="/doradztwo" component={Consulting} />
            <Route exact path="/wspolpraca" component={Partnership} />
            <Route exact path="/kontakt" component={Contact} />
            <Route exact path="/system-nawadniania" component={IrrigationSystem} />
            <Route exact path="/nawozenie" component={Fertilization} />
            <Route exact path="/zarzadzanie-gospodarstwem" component={FarmManagement} />
            <Route exact path="/materialy-do-pobrania" component={DownloadableMaterials} />
            <Route exact path="/galeria" component={Gallery} />
            <Route exact path="/projekty" component={Projects} />
            <Route exact path="/projekty/parp-smartgrow-zabezpiecz-swoje-uprawy" component={Projects_PARP} />
            <Route exact path="/projekty/malinowe-factory" component={RaspberryFactory} />
            <Route exact path="/projekty/borowkowe-factory" component={BlueberryFactory} />
            <Route exact path="/projekty/system-nawadniania-zintegrowany-z-systemem-smartgrow" component={IrrigationSystemProject} />
            <Route exact path="/referencje" component={Reference} />
            <Route exact path="/aktualnosci" component={News} />
            <Route exact path="/aktualnosci/1" component={News_one} />
            <Route exact 
              path="/aktualnosci/sztuczna-inteligencja-uchroni-uprawy-przed-szkodnikami" 
              component={News_two} 
            />
            
            <Route path="*" component={NotFound} />
          </Switch>
      </main>
      <Footer />
    </BrowserRouter>
    </>
  );
}
export default App;

function NotFound() {
  let location = useLocation();

  return (
    <>
      <br />
      <div className="text-center h-100 py-5 my-5 d-flex flex-column align-items-center justify-content-center">
        <h1 className="fw-bold">404</h1>
        <h2 className="fw-bold mb-5">Not Found</h2>
        <h4>
          Nie znaleziono strony <code>{location.pathname}</code>
        </h4>
      </div>
    </>
  );
}