import React from "react";
import { Link } from "react-router-dom";

const RaspberryFactory = () => {
  return(
    <>
    <br />
    <br />
      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container-lg mx-5">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/projekty">Projekty</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Malinowe Factory</li>
          </ol>
        </div>
      </nav>
      <div className="container-lg mb-5 p-5  ">
        <h1 class="fw-light mt-4 mb-5">
          Malinowe Factory
        </h1>
        

      </div>

    </>
  )
}

export default RaspberryFactory;