import React from "react";
import { Link } from "react-router-dom";

const Projects = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Projekty</h1>

      <div class="container-lg p-lg-5">

          <div class="px-5 py-4 bg-light border rounded-3 mb-4">
            <p className="fw-normal h2">PARP - "SmartGrow - zabezpiecz swoje uprawy"</p>
            <Link to="/projekty/parp-smartgrow-zabezpiecz-swoje-uprawy" className="btn btn-smartgrow btn-sm mt-2" type="button">Zobacz szczegóły</Link>
          </div>

          <div class="px-5 py-4 bg-light border rounded-3 mb-4">
            <p className="fw-normal h2">Malinowe Factory</p>
            <Link to="/projekty/malinowe-factory" className="btn btn-smartgrow btn-sm mt-2" type="button">Zobacz szczegóły</Link>
          </div>

          <div class="px-5 py-4 bg-light border rounded-3 mb-4">
            <p className="fw-normal h2">Borówkowe Factory</p>
            <Link to="/projekty/borowkowe-factory" className="btn btn-smartgrow btn-sm mt-2" type="button">Zobacz szczegóły</Link>
          </div>
          
          <div class="px-5 py-4 bg-light border rounded-3 mb-4">
            <p className="fw-normal h2">System nawadniania zintegrowany z systemem SmartGrow</p>
            <Link to="/projekty/system-nawadniania-zintegrowany-z-systemem-smartgrow" className="btn btn-smartgrow btn-sm mt-2" type="button">Zobacz szczegóły</Link>
          </div>
          <hr className="my-5" />
          <div class="px-5 py-4 bg-light border rounded-3 mb-4">
            <p className="fw-normal h2">Koncepcje wykorzystania innowacyjnego systemu SmartGrow</p>
          </div>

    </div>


    </>
  )
}

export default Projects;