import React from "react";

const MedicalCannabis = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Konopie siewne</h1>

      <div class="container-lg p-lg-5">
        Konopie siewne

      </div>

    </>
  )
}

export default MedicalCannabis;