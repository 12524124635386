import React, { Component } from 'react';
import { Link, useParams } from "react-router-dom";

class News extends Component {
  state = {
    articles: [
      {
        id: '7820211',
        url: '/aktualnosci/1',
        date: '7 sierpnia 2021', 
        titleShort: 'Sztuczna inteligencja uchroni uprawy przed szkodnikami',
        titleLong: 'Sztuczna inteligencja uchroni uprawy przed szkodnikami. Opryski będą stosowane tylko tam, gdzie występują',
        decription: 'Nowe technologie coraz skuteczniej wspierają pracę rolników. Dzięki inteligentnej platformie tworzonej przez polski start-up uda się uchronić uprawy przed działaniem szkodników poprzez stosowanie chemicznych oprysków wyłącznie w miejscach, w których jest to konieczne.',
        paragraphs: `Wykonywane przez autonomiczne drony fotografie zastąpią zdjęcia satelitarne w procesie planowania obfitości wysiewów nasion, a oparty na działaniu robotów system wykona za rolnika szereg prac, począwszy od przygotowania gleby, aż po umieszczenie sadzonek na grządkach.
        – System SmartGrow będzie się składał z platformy obliczeniowej ze specjalistycznym algorytmem oceny i predykcji danych, systemu czujników mierzących parametry środowiska wzrostu roślin oraz w pełni zautomatyzowanej cyfrowej pułapki feromonowej – zapowiada w rozmowie z agencją informacyjną Newseria Innowacje Grzegorz Piech, członek zarządu SmartGrow.       
        Projekt realizowany przez polski start-up ma na celu optymalizację procesów ochrony roślin. Dzięki powstającemu systemowi SmartGrow plantatorzy będą mogli w optymalny sposób stosować preparaty chemiczne. Dzięki temu ich skuteczność będzie dużo większa, a rośliny będą mniej obciążone pozostałościami chemicznymi.       
        – Informacje z czujników będą spływały do naszej platformy, dzięki czemu będziemy mogli uzyskać informacje o momencie pojawienia się szkodnika, jego ilości oraz tendencji rozwojowej. Plantator mając takie informacje, będzie mógł dynamicznie i racjonalnie podejmować działania odnośnie do ochrony roślin, przez co zwiększy się jego ekonomia w gospodarstwie, zaoszczędzi czas, a co najważniejsze, rośliny będą mniej obciążone chemią – wyjaśnia Grzegorz Piech.`,
      },
      {
        id: '7820212',
        url: '/aktualnosci/sztuczna-inteligencja-uchroni-uprawy-przed-szkodnikami',
        date: '9 sierpnia 2021', 
        titleShort: 'Sztuczna inteligencja uchroni uprawy przed szkodnikami',
        decription: 'Nowe technologie coraz skuteczniej wspierają pracę rolników. Dzięki inteligentnej platformie tworzonej przez polski start-up uda się uchronić uprawy przed działaniem szkodników poprzez stosowanie chemicznych oprysków wyłącznie w miejscach, w których jest to konieczne.',
        paragraphs: {
          p1: 'Wykonywane przez autonomiczne drony fotografie zastąpią zdjęcia satelitarne w procesie planowania obfitości wysiewów nasion, a oparty na działaniu robotów system wykona za rolnika szereg prac, począwszy od przygotowania gleby, aż po umieszczenie sadzonek na grządkach.',
          pl1: '– System SmartGrow będzie się składał z platformy obliczeniowej ze specjalistycznym algorytmem oceny i predykcji danych, systemu czujników mierzących parametry środowiska wzrostu roślin oraz w pełni zautomatyzowanej cyfrowej pułapki feromonowej – zapowiada w rozmowie z agencją informacyjną Newseria Innowacje Grzegorz Piech, członek zarządu SmartGrow.',      
          p2: 'Projekt realizowany przez polski start-up ma na celu optymalizację procesów ochrony roślin. Dzięki powstającemu systemowi SmartGrow plantatorzy będą mogli w optymalny sposób stosować preparaty chemiczne. Dzięki temu ich skuteczność będzie dużo większa, a rośliny będą mniej obciążone pozostałościami chemicznymi.',       
          pl2: '– Informacje z czujników będą spływały do naszej platformy, dzięki czemu będziemy mogli uzyskać informacje o momencie pojawienia się szkodnika, jego ilości oraz tendencji rozwojowej. Plantator mając takie informacje, będzie mógł dynamicznie i racjonalnie podejmować działania odnośnie do ochrony roślin, przez co zwiększy się jego ekonomia w gospodarstwie, zaoszczędzi czas, a co najważniejsze, rośliny będą mniej obciążone chemią – wyjaśnia Grzegorz Piech.',
        }
      },
      
    ]
  }


  render() {
    
    const articles = this.state.articles.map(e => {
      return (
        <div class="px-5 py-4 my-4 border rounded-3" key={e.id}>
          <p class="text-muted">Dodano: {e.date}</p>
          <Link to={e.url} className="text-dark">
            <h2 className="fw-bold pb-2">{e.titleShort}</h2>
          </Link>
          <p className="lead fw-normal">
            {e.decription}
          </p>
          <p className="lead fw-light">
            
          </p>
        </div>
        )
        
    })
    return (
      <>
        <header className="py-5 bg-light border-bottom mb-4 news-hero">
          <div className="py-5 my-0 my-md-4 my-lg-5"></div>
        </header>
        <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Aktualności</h1>

        <div class="container-lg p-lg-5">

          <ul>{articles}</ul>

        </div>

    </>
    )
  }
}

export default News